import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { json2csv } from 'json-2-csv';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  PaginationQuery,
  PaginationResponse,
  sanitizePaginationQuery,
} from '../../interfaces/pagination.interface';
import { DownloadService } from '../../shared/services/download.service';
import { delayRequest } from '../../shared/util/delayed-request.util';

export const ReportName = {
  Compensation: 'compensation',
  PaidTimeOff: 'time',
  Benefits: 'benefits',
  Employees: 'employees',
  PersonalInformation: 'personal-information',
  Dependents: 'dependents',
  Address: 'address',
  PaymentInformation: 'payment-information',
  EmergencyContact: 'emergency-contact',
} as const;
export type ReportName = (typeof ReportName)[keyof typeof ReportName];

export class LoadReport {
  static readonly type = '[Reporting] Load Report';
  constructor(
    public readonly payload: {
      report: ReportName;
    } & PaginationQuery,
  ) {}
}

export class DownloadReport {
  static readonly type = '[Reporting] Download Report';
  constructor(
    public readonly payload: {
      report: ReportName;
    },
  ) {}
}

export interface ReportingStateModel extends BaseStateModel {
  data: any[];
  pagingMeta: PaginationResponse<any[]>['meta'];
}

@State<ReportingStateModel>({
  name: 'reporting',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    data: [],
    pagingMeta: undefined,
  },
})
@Injectable()
export class ReportingState {
  private http = inject(HttpClient);
  private toaster = inject(HotToastService);
  private downloadService = inject(DownloadService);

  @Selector()
  static data(state: ReportingStateModel) {
    return state.data;
  }

  @Selector()
  static columns(state: ReportingStateModel) {
    if (!state.data.length) return [];
    return Object.keys(state.data[0]);
  }

  @Selector()
  static loading(state: ReportingStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: ReportingStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: ReportingStateModel) {
    return state.error;
  }

  @Selector()
  static pagingMeta(state: ReportingStateModel) {
    return state.pagingMeta;
  }

  @Action(LoadReport)
  async loadReport(
    { patchState, getState }: StateContext<ReportingStateModel>,
    { payload }: LoadReport,
  ) {
    patchState({
      loading: true,
      loaded: false,
      error: null,
    });
    try {
      const res = await lastValueFrom(this.loadReportPage(payload));

      patchState({
        data: res.data,
        pagingMeta: res.meta,
      });
    } catch (err) {
      patchState({
        error: err,
      });
    } finally {
      patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(DownloadReport)
  async downloadReport(
    ctx: StateContext<ReportingStateModel>,
    { payload }: DownloadReport,
  ) {
    const toast = this.toaster.loading('Generating report...', {
      duration: undefined,
    });

    try {
      let pageNumber = 1;
      const data: any[] = [];
      while (true) {
        console.log('page', pageNumber);
        const pageRes = await delayRequest(
          lastValueFrom(
            this.loadReportPage({
              report: payload.report,
              pageSize: 500,
              page: pageNumber,
            }),
          ),
        );

        for (const row of pageRes.data) {
          data.push(row);
        }

        if (!pageRes.meta || pageRes.meta?.isLastPage) {
          break;
        }

        pageNumber++;

        toast.updateMessage(
          `Generating report... ${Math.round((pageNumber / pageRes.meta.pageCount) * 100)}%`,
        );
      }

      toast.updateMessage('Saving report...');

      const csv = json2csv(data, {
        expandNestedObjects: true,
      });

      this.downloadService.downloadPlainText(csv, `${payload.report}.csv`);

      this.toaster.success('Downloaded report', {
        duration: 30_000,
      });
    } catch (err) {
      console.error(err);
      this.toaster.error('Error downloading report');
    } finally {
      toast.close();
    }
  }

  private loadReportPage(
    payload: {
      report: ReportName;
    } & PaginationQuery,
  ) {
    return this.http.get<PaginationResponse<any[]>>(
      `${environment.apiUrl}/v1/reporting/${payload.report}`,
      {
        params: {
          ...sanitizePaginationQuery(payload),
        },
      },
    );
  }
}
